<template>
  <div
    class="flex min-h-full flex-1 flex-col px-8 py-8 bg-white text-gray-800 w-full items-center justify-center"
  >
    <div class="flex gap-4 sm:max-w-md flex-col">
      <div class="text-center">
        <h2 class="text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {{ $props.config?.templateCalling ?? "Connecting your call, please hold." }}
        </h2>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <div
            class="inline-block size-32 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-secondary motion-reduce:animate-[spin_1.5s_linear_infinite] text-indigo-600"
            role="status"
          >
            <span
              class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)] text-indigo-600"
              >Loading...</span
            >
          </div>
        </div>
        <button
          v-if="sourceType === SourceType.KIOSK || sourceType === SourceType.QRCODE"
          class="rounded-full bg-red-600 px-4 py-2.5 font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 text-2xl mt-10"
          @click="$emit('close')"
        >
          Hang Up
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { SourceType } from '@/utility/Constants'
import type { BubbleConfig } from 'types/BubbleConfig'
defineProps<{ config: BubbleConfig; isWindowed: boolean; sourceType: SourceType }>()
</script>
<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;
div {
  font-family: 'Inter Var', 'ui-sans-serif', 'system-ui', 'sans-serif';
}
</style>
