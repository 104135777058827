<template>
  <div class="bg-gray-400 cursor-pointer">
    <div class="flex space-x-2 pb-2 pl-2 pt-2 group">
      <div
        @click="$emit('close')"
        @touchstart="$emit('close')"
        class="rounded-full size-4 bg-red-500 flex justify-center items-center hover:brightness-105 relative text-black text-opacity-70 z-20"
        style="font-size: 0.5rem"
      >
        <i class="group-hover:block hidden icon-close-large visible-only-tap"></i>
      </div>
      <div
        @click="$emit('minimize')"
        @touchstart="$emit('minimize')"
        class="rounded-full size-4 bg-yellow-400 flex justify-center items-center hover:brightness-105 relative text-black text-opacity-70 z-20"
        style="font-size: 0.75rem"
      >
        <i class="group-hover:block hidden icon-caret-fill-horizontal-solid visible-only-tap"></i>
      </div>
      <div
        @click="$emit('maximize')"
        @touchstart="$emit('maximize')"
        class="rounded-full size-4 bg-green-500 flex justify-center items-center hover:brightness-105 relative text-black text-opacity-70 z-20"
        style="font-size: 0.75rem"
      >
        <i class="group-hover:block hidden icon-expand-1 visible-only-tap"></i>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
defineEmits<{
  (e: 'close'): void
  (e: 'minimize'): void
  (e: 'maximize'): void
}>()
</script>
<style scoped>
@import url('/src/styles/icons.css');
@tailwind base;
@tailwind components;
@tailwind utilities;
@media (hover: none) {
  .visible-only-tap {
    display: block;
  }
}
</style>
