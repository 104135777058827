<template>
  <div
    class="border-stone-950 border rounded-xl p-4 inline-grid grid-flow-row gauto-cols-auto auto-rows-auto text-wrap font-serif gap-2 max-w-80 bg-white text-slate-950 cursor-pointer"
    @click="$emit('start')"
  >
    <div class="text-md font-semibold text-slate-950">
      {{ $props.config?.templatePopup ?? 'Need assistance? Talk to us live!' }}
    </div>
    <div class="text-end ml-4">
      <i class="icon-close-large cursor-pointer" @click.stop="$emit('closePopup')"></i>
    </div>
    <div class="secondary col-span-2 text-gray-500 italic text-sm">
      (Your audio will turn on when you join)
    </div>
  </div>
</template>
<script setup lang="ts">
import type { BubbleConfig } from '../../types/BubbleConfig'
defineProps<{
  config: BubbleConfig
}>()
defineEmits<{
  (e: 'closePopup'): void
  (e: 'start'): void
}>()
</script>
<style scoped>
@import '/src/styles/icons.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
div {
  font-family: 'Inter Var', 'ui-sans-serif', 'system-ui', 'sans-serif';
}
</style>
